import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { Layout } from "@components/layout";
import { PageHeader } from "@components/reusables/pageHeader";
import { OurDojangContent } from "@components/ourDojang/ourDojangContent";
import { HeadMetadata } from "@components/headMetadata";

const OurDojangIndex = () => {
  
  const query = useStaticQuery(graphql`
    query ourDojangPageQuery {
      pageHeaderQuery: strapiPageHeader(main_link: {url: {eq: "/our-dojang"}}){
        id
        name
        description
        strapi_id
        coverImageGreyScale
        coverImageBlur
        coverImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  `)

  const pageHeaderQuery = query.pageHeaderQuery;

  return (
    <Layout>
      <HeadMetadata location="Our Dojang"></HeadMetadata>
      <PageHeader
        name={pageHeaderQuery?.name ?? ""}
        description={pageHeaderQuery?.description ?? ""}
        coverImageGreyScale={pageHeaderQuery?.coverImageGreyScale ?? ""}
        coverImageBlur={pageHeaderQuery?.coverImageBlur ?? ""}
        coverImage={pageHeaderQuery?.coverImage?.localFile?.childImageSharp?.gatsbyImageData ?? ""}
      ></PageHeader>
      <div className="content-wrapper">
        <OurDojangContent></OurDojangContent>
      </div>
    </Layout>
  )
};

export default OurDojangIndex;