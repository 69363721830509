import { BrandButton } from "@components/reusables/brandButton";
import { IInformationSection } from "@interface/propsInterface";
import { color, screenBreakPoint } from "@styles/styleVariables";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { ButtonEnum } from "src/enums/buttonsEnum";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pushItemLeft } from "@styles/animations";

export const OurDojangSection = ({
  name,
  briefDescription,
  strapiId,
  coverImage,
  url,
  useTemplate,
}: IInformationSection) => {
  return (
    <SDojangSection
      whileInView="after"
      initial="before"
      variants={pushItemLeft}
    >
      <div className="dojang-section-description">
        <h2>{name ?? ""}</h2>
        {useTemplate ? (
          <BrandButton
            link={url ?? ""}
            name="Learn More!"
            id={strapiId ?? ""}
            category={ButtonEnum.Tertiary}
          ></BrandButton>
        ) : (
          <></>
        )}
      </div>
      <div className="dojang-section-image-wrapper">
        <GatsbyImage
          image={coverImage ?? ""}
          alt={name ?? ""}
          className="dojang-section-image"
        ></GatsbyImage>
      </div>
    </SDojangSection>
  );
};

const SDojangSection = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: solid 0.75rem ${color.sunglow};
  padding-top: 1rem;

  .dojang-section-image-wrapper {
    margin-top: 1rem;
    min-width: 100%;
    height: 20rem;
    .dojang-section-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .dojang-section-description {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.66rem;

    h2 {
      color: ${color.oxfordBlue};
    }
  }

  @media (max-width: ${screenBreakPoint.xxs}) {
    flex-direction: column-reverse;

    .dojang-section-description {
      flex-direction: column;
      margin-top: 1rem;
    }
  }
`;
