import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { OurDojangSection } from "./ourDojangSection";

export const OurDojangContent = () => {

  const query = useStaticQuery(graphql`
    query dojangInformationQuery {
      dojangInformationQuery: allStrapiDojangInformation(sort: {fields: rank, order: ASC}) {
        nodes {
          briefDescription
          strapi_id
          name
          useTemplate
          rank
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
          sublink {
            url
            name
            main_link {
              url
              name
            }
          }
        }
      }
    }
  `)

  const dojangInformationQuery = query.dojangInformationQuery.nodes;

  return (
    <SDojangContent>
      {dojangInformationQuery.map((d:any) => 
        <OurDojangSection
          name={d?.name ?? ""}
          briefDescription={d?.briefDescription ?? ""}
          strapiId={d?.strapi_id ?? ""}
          coverImage={d?.coverImage?.localFile?.childImageSharp?.gatsbyImageData ?? ""}
          url={`${d?.sublink?.main_link?.url ?? ""}${d?.sublink?.url ?? ""}`}
          buttonName={d?.sublink?.name ?? ""}
          useTemplate={d?.useTemplate ?? false}
          rank={d?.rank ?? "9999"}
        ></OurDojangSection>
      )}
    </SDojangContent>
  )
}

const SDojangContent  = styled.div`
  min-height: 100vh;
`